import { Navigate, Route, Routes } from "react-router-dom";

import AuthLayout from "../components/authLayout";
import Layout from "../components/layout";
import Loading from "../components/loading";
import React from "react";
import { useAuth } from "../hooks";

const DashboardPage = React.lazy(() => import("./dashboard"));
const FilesPage = React.lazy(() => import("./files"));
const CompaniesPage = React.lazy(() => import("./companies"));
const UsersPage = React.lazy(() => import("./users"));
const ChatsPage = React.lazy(() => import("./chats"));
const CorridorsPage = React.lazy(() => import("./corridors/router"));
const NotFoundPage = React.lazy(() => import("./notFound"));
const AuthRouter = React.lazy(() => import("./auth/router"));

export default function Router() {
  const { isAuth, permissions } = useAuth();

  const NotBeAuth = ({ children }: { children: JSX.Element }) => {
    return isAuth ? <Navigate to="/" replace /> : children;
  };

  const RequireAuth = ({ children }: { children: JSX.Element }) => {
    return isAuth ? children : <Navigate to="/auth/login" replace />;
  };

  return (
    <Routes>
      <Route
        path="auth/*"
        element={
          <React.Suspense fallback={<Loading />}>
            <NotBeAuth>
              <AuthLayout>
                <AuthRouter />
              </AuthLayout>
            </NotBeAuth>
          </React.Suspense>
        }
      />
      <Route
        element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }
      >
        <Route index element={<Navigate to="/dashboard" replace />} />
        <Route
          path="dashboard"
          element={
            <React.Suspense fallback={<Loading />}>
              <DashboardPage />
            </React.Suspense>
          }
        />
        {permissions.FILE_TRANSACTIONS && (
          <Route
            path="files"
            element={
              <React.Suspense fallback={<Loading />}>
                <FilesPage />
              </React.Suspense>
            }
          />
        )}
        {permissions.COMPANY_TRANSACTIONS && (
          <Route
            path="companies"
            element={
              <React.Suspense fallback={<Loading />}>
                <CompaniesPage />
              </React.Suspense>
            }
          />
        )}
        {permissions.USER_TRANSACTIONS && (
          <Route
            path="users"
            element={
              <React.Suspense fallback={<Loading />}>
                <UsersPage />
              </React.Suspense>
            }
          />
        )}
        {permissions.CORRIDOR_TRANSACTIONS && (
          <Route
            path="corridors/*"
            element={
              <React.Suspense fallback={<Loading />}>
                <CorridorsPage />
              </React.Suspense>
            }
          />
        )}
        {permissions.CHAT_TRANSACTIONS && (
          <Route
            path="chats"
            element={
              <React.Suspense fallback={<Loading />}>
                <ChatsPage />
              </React.Suspense>
            }
          />
        )}
        <Route
          path="*"
          element={
            <React.Suspense fallback={<Loading />}>
              <NotFoundPage />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
}

import Sidebar, { ISidebarMenuItem } from "../sidebar";
import { useAuth, useCurrentPage, useTranslation } from "../../hooks";

import Breadcrumbs from "../breadcrumbs";
import ChangePassword from "../changePassword";
import { IProfileMenuItem } from "../profileMenu";
import Navbar from "../navbar";
import { Outlet } from "react-router-dom";
import { useState } from "react";

export default function Layout() {
  const { t } = useTranslation();
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);

  const { currentUser, logout, permissions } = useAuth();
  const { breadcrumbsItems } = useCurrentPage();

  const sidebarMenuItems: Array<ISidebarMenuItem> = [
    {
      title: t("dashboard"),
      iconName: "ri-dashboard-line",
      url: "/dashboard",
      description: t("dashboardPageDescription"),
    },
    {
      title: t("companies"),
      iconName: "ri-building-line",
      url: "/companies",
      description: t("companiesPageDescription"),
      visible: permissions.COMPANY_TRANSACTIONS,
    },
    {
      title: t("users"),
      iconName: "ri-group-line",
      url: "/users",
      description: t("usersPageDescription"),
      visible: permissions.USER_TRANSACTIONS,
    },
    {
      title: t("corridors"),
      iconName: "ri-bard-line",
      url: "/corridors",
      description: t("corridorsPageDescription"),
      visible: permissions.CORRIDOR_TRANSACTIONS,
    },
    {
      title: t("chats"),
      iconName: "ri-chat-3-line",
      url: "/chats",
      description: t("chatsPageDescription"),
      visible: permissions.CHAT_TRANSACTIONS,
    },
    {
      title: t("files"),
      iconName: "ri-folder-line",
      url: "/files",
      description: t("filesPageDescription"),
      visible: permissions.FILE_TRANSACTIONS,
    },
  ];

  const profileMenuItems: Array<IProfileMenuItem> = [
    {
      label: t("changePassword"),
      onClick: () => setIsChangePasswordOpen(true),
    },
    {
      label: t("logout"),
      onClick: () => {
        logout();
        window.location.href = "/auth/login";
      },
    },
  ];

  return (
    <>
      <div className="flex min-h-screen max-h-max max-h-content w-screen bg-slate-100">
        <Sidebar menuItems={sidebarMenuItems} />
        <div className="relative flex-1 md:overflow-hidden max-w-full">
          <Navbar user={currentUser} menuItems={profileMenuItems} />
          <div className="md:absolute md:inset-x-0 md:top-12 md:bottom-0">
            <div className="py-6 px-6 md:px-8 h-full no-scrollbar md:overflow-y-auto md:pt-12 ">
              <main className="mx-auto max-w-content-xl">
                {breadcrumbsItems && breadcrumbsItems.length > 0 && (
                  <Breadcrumbs items={breadcrumbsItems} />
                )}
                <Outlet />
              </main>
            </div>
          </div>
        </div>
      </div>
      {isChangePasswordOpen && (
        <ChangePassword
          isOpen={isChangePasswordOpen}
          onClose={() => setIsChangePasswordOpen(false)}
        />
      )}
    </>
  );
}

import { apolloClient } from "../clients";
import { IStorage, IFileUploadResponse } from "../../models";
import { UPLOAD_WITH_BASE64 } from "./_gql";

export class StorageService {
  public static async fileUploadAsync(
    storage: IStorage,
  ): Promise<IFileUploadResponse> {
    const response: any = await apolloClient.mutate<{
      uploadWithBase64: IFileUploadResponse;
    }>({
      mutation: UPLOAD_WITH_BASE64,
      variables: {
        input: storage,
      },
    });

    return response.data.uploadWithBase64;
  }
}

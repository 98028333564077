import moment from "moment/moment";

export class DateHelperService {
  public static getFormattedChatMessageDate(
    date: string | undefined,
    format?: string,
  ): string {
    if (!date) return "";

    const now: moment.Moment = moment();
    const messageTime: moment.Moment = moment(date);

    const isSameDay: boolean = now.isSame(messageTime, "day");
    const hoursDifference: number = now.diff(messageTime, "hours");

    return hoursDifference < 24 && isSameDay
      ? messageTime.format("HH:mm")
      : messageTime.format(format ?? "DD.MM.YYYY");
  }
}

import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  split,
} from "@apollo/client";

import { AuthService } from "../services";
import { configurations } from "../../config/configurations";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import {
  FragmentDefinitionNode,
  OperationDefinitionNode,
} from "graphql/language";

const httpLink: ApolloLink = createHttpLink({
  uri: configurations.apiEndpoint,
});

const wsLink: ApolloLink = new WebSocketLink({
  uri: configurations.webSocketEndpoint as string,
  options: {
    reconnect: true,
    connectionParams: () => {
      const token = AuthService.getToken();
      return {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      };
    },
  },
});

const authLink: ApolloLink = setContext((_, { headers }) => {
  const token = AuthService.getToken();
  return {
    headers: token
      ? {
          ...headers,
          Authorization: `Bearer ${token}`,
        }
      : headers,
  };
});

const subscriptionAwareLink: ApolloLink = split(
  ({ query }) => {
    const mainDefinition: OperationDefinitionNode | FragmentDefinitionNode =
      getMainDefinition(query);
    return (
      mainDefinition.kind === "OperationDefinition" &&
      mainDefinition.operation === "subscription"
    );
  },
  wsLink,
  httpLink,
);

function createApolloClient(): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    link: authLink.concat(subscriptionAwareLink),
    cache: new InMemoryCache(),
  });
}

export const apolloClient: ApolloClient<NormalizedCacheObject> =
  createApolloClient();

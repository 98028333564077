import { gql } from "@apollo/client";

export const GET_USER = gql`
  query getUser($userId: Int!) {
    usersByPk(id: $userId) {
      id
      fullName
      firstName
      isActive
      lastName
      emailAddress
      companyId
      role
      permissions {
        id
        permission {
          key
          id
        }
      }
    }
  }
`;

export const UPLOAD_WITH_BASE64 = gql`
  mutation uploadWithBase64($input: UploadWithBase64Request!) {
    uploadWithBase64(input: $input) {
      id
      fileType
      companyId
      name
      url
      createdAt
    }
  }
`;

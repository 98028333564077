import {
  AuthProvider,
  CurrentPageProvider,
  TranslationProvider,
} from "./hooks";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { ApolloProvider } from "@apollo/client";
import { NextUIProvider } from "@nextui-org/react";
import Router from "./pages/router";
import { ToastContainer } from "react-toastify";
import { apolloClient } from "./utils/clients";
import moment from "moment-timezone";

const browserTimeZone: string =
  Intl.DateTimeFormat().resolvedOptions().timeZone;
moment.tz.setDefault(browserTimeZone);

export default function App() {
  const navigate: NavigateFunction = useNavigate();

  return (
    <ApolloProvider client={apolloClient}>
      <AuthProvider>
        <TranslationProvider>
          <NextUIProvider navigate={navigate}>
            <CurrentPageProvider>
              <ToastContainer />
              <Router />
            </CurrentPageProvider>
          </NextUIProvider>
        </TranslationProvider>
      </AuthProvider>
    </ApolloProvider>
  );
}
